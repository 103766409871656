import { Injectable } from "@angular/core";
import { UtilityService } from "../../services/utility.service";
import { AjaxServices } from '../../services/ajax-services/ajax-service.service';
import { map } from "rxjs";
import { AuthService } from "../../services/auth.service";

declare var window;
@Injectable()
export class AppConfig {
  isAuthenticated = false;
  userData;
  constructor(
    private utilityService: UtilityService,
    private http: AjaxServices,
    private authService: AuthService
  ) { }

  load() {
    const currentServiceObj = this.utilityService.fetchCurrentServiceObj();
    this.utilityService.currentBrowser();
    if (!currentServiceObj) {
      this.utilityService.fetchServiceHost();
    }

    // S-28540 (S-26832) - Removed ngconfig api call from here and added in main.ts file since require some okta config values before app load
    // which will come from ngconfig api
        return new Promise<any>((resolve, reject) => {
          if (this.utilityService.isCsr) {
            resolve(true)
          } else {
            this.authService.getpublicAccess().pipe(
              map((data) => {
                sessionStorage.removeItem('accessToken');
                if (data && data.enableDeathNotification === 'Y') {
                  this.authService.isFeatureEnabled = true;
                  resolve(true);
                } else {
                  this.authService.isFeatureEnabled = false;
                  resolve(true);
                }
              })
            ).subscribe();
          }
      })
  }

}
