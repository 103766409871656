export const environment = {
  production: true,
  name: "prod",
  intgB: {
    test: false
  }
};
/**
 * Defines environment related constants / Configuration blocks
 *
 * Environment Block
 *
 * <ENV CODE>: {
 *  internalHost: host name used for Internal Users
 *  externalHost: host name for External Users
 *  publicWebsiteHost: host name for public website
 *  serviceInternal: API Gateway Service URL for Internal Users
 *  serviceExternal: API Gateway Service URL for External Users
 *  integraionNameInternal: API Gateway Stage Name for Internal Users
 *  integrationNameExternal: API Gateway Stage Name for Internal Users
 *  integrationNamePublic: API Gateway Stage Name for Public APIs,
 *  clientId: okta client Id for the environment
 *  csrGroupConfig: GroupConfig as mention in Okta for Csr
 *  issuer: Party that is responsible for signin with help of token or key
 *  isProd: To check wheather its Prod Environment or Not
 *  isJsExceptionLogAllowed: Js logs for lower envirnoment
 * }
 *
 */
export const ENVIRONMENTCONFIG = {
  DEVA: {
    internalHost: "https://venerableportal-a.dev.vaapps.net",
    externallbHost: "https://mya.dev.vaapps.net",
    externalHost: "https://mya.dev.venerable.com",
    publicWebsiteHost: "https://dev.venerable.com",
    serviceInternal: "https://ven-int-services-web.dev.vaapps.net/",
    serviceExternalLb: "https://ven-ext-services-web.dev.vaapps.net/",
    serviceExternal: "https://services.dev.venerableannuity.com/",
    integraionNameInternal: "portal-int/claims/",
    integrationNameExternal: "portal-ext/claims/",
    integrationNamePublic: "",
  },
  DEVB: {
    internalHost: "https://venerableportal-b.dev.vaapps.net",
    externallbHost: "https://myb.dev.vaapps.net",
    externalHost: "https://myb.dev.venerable.com",
    publicWebsiteHost: "https://dev.venerable.com",
    serviceInternal: "https://ven-int-services-web.dev.vaapps.net/",
    serviceExternalLb: "https://services.dev.venerableannuity.com/",
    serviceExternal: "https://services.dev.venerableannuity.com/",
    integraionNameInternal: "portal-int/claims/",
    integrationNameExternal: "portal-ext-b/claims/",
    integrationNamePublic: "",
  },
  INTG: {
    internalHost: "https://venerableportal.intg.vaapps.net",
    externallbHost: "https://my.intg.vaapps.net",
    externalHost: "https://my.intg.venerable.com",
    publicWebsiteHost: "https://accp.venerable.com",
    serviceInternal: "https://ven-int-services-web.intg.vaapps.net/",
    serviceExternalLb: "https://ven-ext-services-web.intg.vaapps.net/",
    serviceExternal: "https://services.intg.venerableannuity.com/",
    integraionNameInternal: "portal-int/claims/",
    integrationNameExternal: "portal-ext/claims/",
    integrationNamePublic: "",
  },
  INTGB: {
    internalHost: "https://venerableportalb.intg.vaapps.net",
    externallbHost: "https://myb.intg.vaapps.net",
    externalHost: "https://myb.intg.venerable.com",
    publicWebsiteHost: "https://accp.venerable.com",
    serviceInternal: "https://ven-int-services-web.intg.vaapps.net/",
    serviceExternalLb: "https://ven-ext-services-web.intg.vaapps.net/",
    serviceExternal: "https://services.intg.venerableannuity.com/",
    integraionNameInternal: "portal-int/claims/",
    integrationNameExternal: "portal-ext-b/claims/",
    integrationNamePublic: "",
  },
  ACCP: {
    internalHost: "https://venerableportal.accp.vaapps.net",
    externallbHost: "https://my.accp.vaapps.net",
    externalHost: "https://my.accp.venerable.com",
    publicWebsiteHost: "https://accp.venerable.com",
    serviceInternal: "https://ven-int-services-web.accp.vaapps.net/",
    serviceExternalLb: "https://ven-ext-services-web.accp.vaapps.net/",
    serviceExternal: "https://services.accp.venerableannuity.com/",
    integraionNameInternal: "portal-int/claims/",
    integrationNameExternal: "portal-ext/claims/",
    integrationNamePublic: "",
  },
  ACCPB: {
    internalHost: "https://venerableportalb.accp.vaapps.net",
    externallbHost: "https://myb.accp.vaapps.net",
    externalHost: "https://myb.accp.venerable.com",
    publicWebsiteHost: "https://accp.venerable.com",
    serviceInternal: "https://ven-int-services-web.accp.vaapps.net/",
    serviceExternalLb: "https://ven-ext-services-web.accp.vaapps.net/",
    serviceExternal: "https://services.accp.venerableannuity.com/",
    integraionNameInternal: "portal-int-b/claims/",
    integrationNameExternal: "portal-ext-b/claims/",
    integrationNamePublic: "",
  },
  ACCPC: {
    internalHost: "https://venerableportalc.accp.vaapps.net",
    externallbHost: "https://myc.accp.vaapps.net",
    externalHost: "https://myc.accp.venerable.com",
    publicWebsiteHost: "https://accp.venerable.com",
    serviceInternal: "https://ven-int-services-web.accp.vaapps.net/",
    serviceExternalLb: "https://ven-ext-services-web.accp.vaapps.net/",
    serviceExternal: "https://services.accp.venerableannuity.com/",
    integraionNameInternal: "portal-int-c/claims/",
    integrationNameExternal: "portal-ext-c/claims/",
    integrationNamePublic: "",
  },

  PDR: {
    internalHost: "https://venerableportal-dr-pprd.vaapps.net",
    externalHost: "https://my-dr-pprd.venerable.com",
    serviceInternal: "https://ven-int-services-dr-web.prod.vaapps.net/",
    serviceExternalLb: "NA",
    serviceExternal: "https://services-dr.venerableannuity.com/",
    integraionNameInternal: "portal-int/claims/",
    integrationNameExternal: "portal-ext/claims/",
    integrationNamePublic: "",
  },
  //need to know if we have a lb host for Dr region
  DR: {
    internalHost: "https://venerableportal-dr.vaapps.net",
    externallbHost: "https://venerableportal-dr.vaapps.net",
    externalHost: "https://my-dr.venerable.com",
    publicWebsiteHost: "https://dr.venerable.com",
    serviceInternal: "https://ven-int-services-dr-web.prod.vaapps.net/",
    serviceExternalLb: "https://ven-ext-services-dr-web.prod.vaapps.net/",
    serviceExternal: "https://services-dr.venerableannuity.com/",
    integraionNameInternal: "portal-int/claims/",
    integrationNameExternal: "portal-ext/claims/",
    integrationNamePublic: "",
  },
  PPROD: {
    internalHost: "https://venerableportal-pprd.vaapps.net",
    externallbHost: "https://my-pprd.vaapps.net",
    externalHost: "https://my-pprd.venerable.com",
    publicWebsiteHost: "https://my-pprd.venerable.com",
    serviceInternal: "https://ven-int-services-web.prod.vaapps.net/",
    serviceExternalLb: "https://ven-ext-services-web.prod.vaapps.net/",
    serviceExternal: "https://services.venerableannuity.com/",
    integraionNameInternal: "portal-int-pprd/claims/",
    integrationNameExternal: "portal-ext-pprd/claims/",
    integrationNamePublic: "",
  },
  PROD: {
    internalHost: "https://venerableportal.vaapps.net",
    externallbHost: "https://my.vaapps.net",
    externalHost: "https://my.venerable.com",
    publicWebsiteHost: "https://venerable.com",
    serviceInternal: "https://ven-int-services-web.prod.vaapps.net/",
    serviceExternalLb: "https://ven-ext-services-web.prod.vaapps.net/",
    serviceExternal: "https://services.venerableannuity.com/",
    integraionNameInternal: "portal-int/claims/",
    integrationNameExternal: "portal-ext/claims/",
    integrationNamePublic: "",
  },
  localhost: {
    internalHost: "http://localhost:8101",
    externalHost: "http://localhost:8102",
    publicWebsiteHost: "http://localhost:4200",
    serviceInternal: "https://localhost:8100/portal-claims-api/",
    serviceExternal: "https://localhost:8100/portal-claims-api/",
    integraionNameInternal: "int/",
    integrationNameExternal: "ext/",
    integrationNamePublic: "",
  }
};
Object.freeze(ENVIRONMENTCONFIG);

// Not used as of now , framework was created to support child resources URI pattern after stage name
// Keeping it for any future reuse perspective .
export const PROXYPATH = {
  csrUser: {
    external: "",
    internal: ""
  },
  nonCsrUser: {
    external: "",
    internal: ""
  }
};

Object.freeze(PROXYPATH);
