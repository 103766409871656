import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Invaliddatetype } from 'src/app/core/global/interface/invaliddatetype';

@Component({
  selector: 'app-lib-date-prime',
  templateUrl: './date-range-picker-prime.component.html',
  styleUrls: ['./date-range-picker-prime.component.scss']
})
export class DateRangePickerPrimeComponent implements OnChanges,OnInit {
  @Input() dateRange;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() isReadOnly: boolean;
  @Input() invalidDateList: Invaliddatetype[];
  @Input() readOnly = false;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @Output() clearEvent: EventEmitter<any> = new EventEmitter();
  @Output() changeEvent: EventEmitter<any> = new EventEmitter();
  @Input() showButtonBar:boolean=false;
  @Input() selectionMode:string='single';
  @Input() dateFormat:string="yyyy-MM-dd";
  @Input() rangeSingleDateAllow=false;
  @Input() disabled:boolean=false;
  @Input() baseZIndex:number=10000;
  @Input() placeholder:string = "Choose date";
  styleInvalidDate: any;
  disabledWeekDays: number[] = []; // days which needs to be disabled in calendar
  disabledDateList: Date[] = []; // dates which needs to be disabled
  defaultDateRange;
  constructor(private datePipe: DatePipe) {
  }
  ngOnInit() {
    this.defaultDateRange=this.dateRange;
  }
  onChange(ev) {
      this.dateRange = ev;
      this.setStartEndDateStyles();
      if (this.selectionMode === 'single') {
          const date = this.datePipe.transform(this.dateRange, this.dateFormat)
          this.submitEvent.emit(date);
        } else if (ev && ev[0] && ev[1]) {
            const date = [];
            date[0] = this.datePipe.transform(this.dateRange[0], this.dateFormat);
            date[1] = this.datePipe.transform(this.dateRange[1], this.dateFormat);
            this.submitEvent.emit(date);
        } else if (ev && this.rangeSingleDateAllow && (ev[0] || ev[1])) {
            const date = [];
            date[0] = this.datePipe.transform(this.dateRange[0], this.dateFormat);
            date[1] = this.datePipe.transform(this.dateRange[1], this.dateFormat);
            this.submitEvent.emit(date);
        }
        this.changeEvent.emit(ev);
  }
  ngOnChanges(changes: SimpleChanges) {
    // as we are not using date time picker and it's only a date picker so we are setting mindate time to 00:00:00
    // and max date time to 23:59:59 so that passed selected date object can't create issue having same date as max or min date
    // and time which is greater than max date time or less than min date time  fixed D-01516 D-02598
    if (changes && changes.minDate && changes.minDate.currentValue) {
      this.minDate = changes.minDate.currentValue;
      this.minDate.setHours(0);
      this.minDate.setMinutes(0);
      this.minDate.setSeconds(0);
    }
    if (changes && changes.maxDate && changes.maxDate.currentValue) {
      this.maxDate = changes.maxDate.currentValue;
      this.maxDate.setHours(23);
      this.maxDate.setMinutes(59);
      this.maxDate.setSeconds(59);
    }
    if (changes && (changes.invalidDateList || changes.minDate || changes.maxDate)) {
      this.disableDate();
    }
  }

  disableDate() {
      if (this.invalidDateList && this.invalidDateList.length) {
          this.invalidDateList.map(item => {
              if (item.type === "weekendoff") {
                  this.disabledWeekDays = [0, 6];
              }
              if (item.type === "specificdaywithweekendoff") {
                this.disabledWeekDays = [0, 6];
                  this.disabledDateList = item.dateList.map(item => {
                    return new Date(item)
                  });
              }
              if (item.type === "specificmonthdaysoff") {
                this.disabledDateList = this.prepareDisabledDateList(this.minDate, this.maxDate, item.dateList);
              }
          })
      }

  }
    private prepareDisabledDateList(minDate: Date, maxDate: Date, dateList: string[]): Date[] {
        let some_date = new Date(minDate);
        let disabled_date_list: Date[] = [];
        while(some_date <= maxDate) {
            if (dateList.includes(some_date.getDate().toString())) {
                disabled_date_list.push(new Date(some_date));
            }
            some_date.setDate(some_date.getDate()+1);
        }
        return disabled_date_list;
    }
  checkDate(date):number {
      if (this.invalidDateList && this.invalidDateList.length) {
          this.invalidDateList.map(item => {
              if (item.type === "specificdate") {
                  if (item.dateList.includes(date.day.toString())) {
                      date.selectable = false;
                  }
              }
          })
      }
      return date.day as number;
  }

  onCalendarPanelShow() {
      this.setStartEndDateStyles();
     }
   
  setStartEndDateStyles() {
      setTimeout(() => {
        const highlightedElementsList=document.querySelectorAll('.p-datepicker table td span.p-highlight');
          if (highlightedElementsList) {
              highlightedElementsList.forEach((ele,index)=>{
                if(index===0){
                  (<HTMLElement>highlightedElementsList[0]).classList.add('startDate');
                }
                else if(index===highlightedElementsList.length-1){
                  (<HTMLElement>highlightedElementsList[highlightedElementsList.length - 1]).classList.add('endDate');
                }
                else{
                  (<HTMLElement>highlightedElementsList[index]).classList.remove('endDate');
                  (<HTMLElement>highlightedElementsList[index]).classList.remove('startDate');
                }
              })
          }
      });
  }

  monthChangeEvent() {
      this.setStartEndDateStyles();

  }

  closeEvent() {
      if (this.selectionMode === 'range' && this.dateRange && !this.dateRange[1]) {
          this.dateRange = this.defaultDateRange
      }
  }
  clearButtonEvent() {
      this.clearEvent.emit(true)
  }
}