export const ClaimsManagementConstants = {
 IS_PRT: "isPRT",
 OWNER_FIRST: "Owner First",
 OWNER_LAST: "Owner Last",
 OWNER_SSN: "Owner SSN",
 CONTRACT: "Contract #",
 CONTRACT_STATUS: "Contract Status",
 CLAIMS_DUE_DILIGENCE:"Claims Due Diligence",
 NEXT_CONTACT_ATTEMPT:"Next Contact Attempt",
 BENE_STATUS: "Bene Status",
 BENE_FIRST: "Bene First",
 BENE_LAST: "Bene Last",
 BENE_SSN: "Bene SSN",
 PERFORM_SEARCH_TEXT: "Perform New Search",
 URL_CLAIMS: '/csr/claimsmanagement',
 ERROR_MSG: "no matching records found",
 EMPTY_SPACE: "",
 SSN_MIN_LENGTH: 9,
 SSN_MAX_LENGTH: 9,
 LABEL_ENTER_CONTRACT: "Enter Contract Number",
 NAME_MIN_LENGTH: 2,
 SSN_TIN_VALUE: "BeneSSN",
 SSN_VALUE_OWNER: "ownerSSN",
 ENTER_SSN: "Enter SSN",
 CONTRACT_VALUE: "contractNumber",
 CONTRACT_LABEL: "Contract #",
 CONTRACT_NUMBER1: "CONTRACT NUMBER",

 FN_VALUE: "name",
 FN_LABEL: "benefullName",

 LABEL_ENTER_FNAME: "Enter First Name",
 LABEL_ENTER_LNAME: "Enter Last Name",

 OWNER_FULL_NAME: "ownerfullName",
 FORM_INVALID: "INVALID",

 ERROR_SSN_REQUIRED: "SSN/TIN is required.",
 ERROR_SSN_MIN_LENGTH: "When searching for an SSN/TIN, you must use a full nine-digit SSN/TIN.",

 ERROR_CONTRACT_REQUIRED: "Contract Number is required.",
 ERROR_FIRST_LAST_REQUIRED: "First Name or Last Name is required.",
 LAST_NAME_REQUIRED:"Last Name is required",
 CONTRACT_NUMBER_LABLE: "Contract Number",
 BENEFICIARY_SSN: "Beneficiary SSN",
 BENEFICIARY_NAME: "Beneficiary First and Last Name",
 OWNERS_NAME: "Owner First and Last Name",

 DECEDENT_NAME: "DECEDENT NAME",
 DATE_OF_DEATH: "DATE OF DEATH",
 CONTRACT_STATUS1: "CONTRACT STATUS",
 GLOBAL_ERROR: "No results were found",

 BENEFICIARY_UPDATE:"Select all beneficiaries that apply to this update.",
 REQUIRED: "*",
 CHAR: "Characters:",
 NO_BENEFICIARY_ON_FILE:"No beneficiaries on file",
 BENEFICIARY_CONTACT_LIST:"beneficiaryContractList",
 CONTRACT_NO:"contractNo",
 NO_PERMISSION_ERROR_MSG:"You do not have permission to perform updates.",
 SYSTEM_MAINTENANCE_ERROR_MSG: "The Claims Wrapper is not available at this time, please try again later.",
 SSN_ERROR_MSG: "9 digits are required for the SSN",
 EMAIL_INVALID:"Email is invalid",
 PHONE_INVALID:"Phone Number is invalid",
 CONTRACT_TYPE:"contractType",
 NO_DEATH_PAYBLE_ERROR_MSG:"There is no Death Benefit payable for this contract.",
 SEND_CLAIMKIT_SUCCESS_MSGS : "Form successfully requested for this beneficiary.",
 SUCCESS_MSGS : "Successfully updated.",
 CLAIMS_WRAPPER: "Claims Wrapper",
 USER_TYPE_NOT_FOUND: "NA",
 CLAIMS_UPDATE: "Claims Updates",
 CONTRACT_TYPE_LC:"LC",
 BENEFICIARY_ADDRESS: "BENEFICIARY ADDRESS",
 UNITED_STATES: "United States",
 FOREIGN_ADDRESS_QUESTION: "Is the address a foreign address?",
 COUNTRY_REQUIRED_ERROR: "Country is required",
 //Taxpayer Certification Constants
 TAXPAYER_CERTIFICATION_TITLE: "U.S. Taxpayer Certification",
 TAXPAYER_CERTIFICATION_CONTENT_TITLE: "By checking the below and clicking Submit, you certify that:",
 TAXPAYER_CERTIFICATION_POINT_1: "1. The Taxpayer Identification Number or Social Security Number I entered above is correct.",
 TAXPAYER_CERTIFICATION_POINT_2: "2. I am not subject to back-up withholding due to failure to report interest and dividend income:",
 TAXPAYER_CERTIFICATION_SUB_POINT_2: "If I am subject to backup withholding, I have checked here.",
 TAXPAYER_CERTIFICATION_POINT_3: "3. I am a U.S. person.",
 //Non-Resident Alien Constants
 NON_RESIDENT_ALIEN_TITLE: "NON-RESIDENT ALIEN STATUS",
 NON_RESIDENT_ALIEN_CONTENT_TITLE: "If you are a Non-Resident Alien, please check the box and provide your country of residence below.",
 NON_RESIDENT_ALIEN_POINT_1: "Under penalties of perjury, I certify that I am a Non-Resident Alien and my country of residence is:",
 NON_RESIDENT_ALIEN_POINT_2: "The amount paid to you will be subject to 30% tax withholding unless you submit an IRS Form W-8 and are entitled to claim a reduced rate of withholding under the applicable U.S. tax treaty.",
 //Acknowledgement Constants
 ACKNOWLEDEGE_MSG_1: "I have read and agree to the ",
 ACKNOWLEDEGE_MSG_2: "Terms and Conditions.",
 AGREEMENT_URL: "/content/public/forms/VEN_206770.pdf",
 ACCKNOWLEDGE_ERROR: "To continue, please select the link below to review and then agree to the Terms and Conditions."
};

export const BENE_TYPE_LIST_FOR_CLAIM_KIT: { [key: string]: string } = {
    1:"Spousal Continuation",
    2:"Lump Sum",
    3:"Lump Exchange",
    4:"Deferral",
    5:"Life Expectancy Deferral",
    6:"Annuitization",
    7:"Lump Sum of Commuted Value",
    8:"Continue Annuity Payments",
    9:"Bene W-9",
   "9A":"One Pager"
  }

  export const RelationshipForTrustee=['TRUST','ESTATE']

 